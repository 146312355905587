@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap'); */

body {
  margin: 0;
  font-family: "Roboto", Helvetica, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
    font-weight: normal;
}

::-webkit-scrollbar {
  width: 5px;
 height: 5px;
  
  }

  /* Track */
  
  ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  }
  
  /* Handle */
  
::-webkit-scrollbar-thumb {
background: rgb(165, 165, 165);
border-radius: 10px;
}
  
  /* Handle on hover */
  
::-webkit-scrollbar-thumb:hover {
background: grey;


}   

 .modal-open .modal{
  overflow-y: hidden !important;;
} 
/* 
 .nav-model{
  margin-top: -200px;
}   */




.backdrop-edit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Adjust the alpha (4th parameter) for transparency */
  overflow-y: hidden !important;
 /* Initially hide the backdrop */
}
.modal-edit-background{
  position:absolute;
  background-color: #fff;
  top:5%;
  left: 27%;
  overflow-y: hidden !important;
  width:45%;
  padding: 25px;
  border-radius: 10px;
}

.dropdown-item{
  border-bottom: 1px solid #E3C9F2 !important;
  font-size: 15px;
  /* width:250px !important; */
  text-overflow: ellipsis;
}

.dropdown-item:hover{
background-color: #600097 !important;
color:white !important;
}


.dropdown-item:active{
  background-color: #600097 !important;
}

 .dropdown-toggle::after {
  display: none !important; 
}

.btn.show{
background-color: none !important;
}


.react-responsive-modal-modal {
  max-width: 800px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
  width:55%;
  border-radius: 6px;
  
}


