.tableTitleButtonDiv{
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
}

.titleButton{
  width: 130px;
  height: 30px;
  background: transparent url('../../../assets/addNewButton.svg') 0% 0% no-repeat padding-box;
  border: none;
  margin-left: 5px;
}

.custom-datatable-title {
    font-size: 14px;
    color: #333333;
    opacity: 1;
    font-weight: bold;
    background: none;
    display: flex;
   }

   /* .lfDaYD{
    background-color: transparent;
   } */