@import url("../../Styles/sassStyles.css");

/* .main-container {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7 !important;
  overflow: scroll;
} */

.tabs-head {
  width: 100%;
  height: 100%;
  /* height: 90%; */
  /* overflow: hidden; */
}

.sidemenu-section-settings {
  min-height: 100vh;
  background: var(--primary-shade1) 0% 0% no-repeat padding-box;
}

.setting-layout {
  /* height: 100vh; */
  background-color: #f7f7f7;
}

.tabs {
  width: 100%;
}

/**************** interview setiing CSS ***************/

.saveButton {
  width: 120px;
  height: 32px;
  background: var(--secondary-btn) 0% 0% no-repeat padding-box;
  color: white;
  font-size: 16px;
  border: 1px solid var(--secondary-btn);
  border-radius: 4px;
  opacity: 1;
}

.parentDiv {
  margin: 30px;
  /* width: 100%; */
  height: 79vh;
}

.parentDiv p {
  font-size: 14px;
  color: #333333;
  opacity: 1;
  font-family: "Roboto", sans-serif;
}

/**************** interview setiing CSS end ***************/

/* *******************Tabs CSS  ****************************/

.react-tabs .react-tabs__tab {
  /* display: inline; */
  color: #a1a1a1;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  opacity: 1;
}

.react-tabs .react-tabs__tab--selected {
  color: var(--primary-color);
  font-weight: bold;
  letter-spacing: 0px;
  border: none;
  background: none;
  border-bottom: 3px solid var(--primary-color);
  /* border-bottom-color: transparent; */
}

.react-tabs .react-tabs__tab-list {
  border-bottom: 1px solid var(--primary-shade1);
  display: flex;
  justify-content: space-evenly;
}

.react-tabs .react-tabs__tab--selected:focus {
  border: none;
  /* Remove the default focus outline */
  /* Add other styles you want */
}

/* *******************Tabs CSS End  ****************************/

/* *****************E-mail template css ************************/

[data-column-id="action"].rdt_TableCol {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
  padding-right: 40px;
}

.custom-datatable-title {
  font-size: 14px;
  color: 333333;
  opacity: 1;
  font-weight: bold;
  background: none;
  display: flex;
}

.rdt_TableHeader {
  background-color: transparent;
}

.rdt_Table {
  height: auto;
}

/* *****************E-mail template css end ************************/

/* *****************License Tab Style Satrt ************* */

.label {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  opacity: 1;
  text-align: left;
  color: #333333;
}

.basicButton {
  width: 120px;
  height: 32px;
  background: transparent linear-gradient(104deg, #009bf8 0%, #00dffe 100%) 0%
    0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  border: none;
  font-size: 16px;
  color: white;
}

.upgradeButton {
  width: 120px;
  height: 32px;
  background: var(--primary-btn) 0% 0% no-repeat padding-box;
  color: white;
  font-size: 16px;
  border: 1px solid var(--primary-btn);
  border-radius: 4px;
  opacity: 1;
}

.customCard {
  background-color: #ffffff;
  border-radius: 20px;
  height: 340px;
  width: 280px;
  border: none;
  box-shadow: 0px 3px 6px #0000001a;
  position: relative;
  margin-bottom: 10px;
}

.cardWrap {
  padding: 30px;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.plusCardTitle {
  background: transparent linear-gradient(104deg, #008e21 0%, #00c22d 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  font-size: 20px;
  font-weight: bold;
  color: white;
  width: 240px;
  height: 50px;
  border-radius: 10px;
}

/* ****************ATS integartion*************** */

.ats-card {
  width: 400px;
  height: 230px;
}

.ats-row-card {
  gap: 50px;
}

.ats-card-columncard {
  width: 400px !important;
}

@media screen and (max-width: 1400px) {
  /* Your styles for screens with a width of 1366 pixels or more */
  .ats-card {
    width: 300px;
    height: 230px;
  }

  .ats-card-columncard {
    width: 300px !important;
  }

  .ats-row-card {
    gap: 10px;
  }

  /* Add more styles as needed */
}

.connectButton {
  /* top: 318px;
  left: 238px;
  width: 138px;
  height: 24px; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  font-size: 14px;
  color: #a1a1a1;
  justify-content: space-between;
}

.connectButton::after {
  content: "Connect";
}
.connectButton:hover::after {
  content: "Connect";
}

.connectButton:hover {
  background: #e4fae8 0% 0% no-repeat padding-box;
  color: #00c22d;
}

.connectedButton {
  width: 138px;
  height: 24px;
  /* UI Properties */
  background: #e4fae8 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  font-size: 14px;
  color: #00c22d;
  font-weight: normal;
  justify-content: space-between;
  border: none;
  transition: background-color 0.3s ease;
}

.connectedButton::after {
  content: "Connected";
}

.connectedButton:hover::after {
  content: "Disconnect";
}

.connectedButton:hover {
  background-color: #ffff;
  color: #a1a1a1;
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  border: 1px solid #e9e9e9;
}
.connectImg {
  background: transparent url("../../assets/connect-solid.svg") 0% 0% no-repeat
    padding-box;
  width: 34px;
  height: 10px;
}

.connectedImage {
  background: transparent url("../../assets/connectedImg.svg") 0% 0% no-repeat
    padding-box;
}

.connectText {
  /* UI Properties */
  font-size: 14px;
  color: #a1a1a1;
  width: 52px;
  height: 17px;
  text-align: left;
}

/* ********Authentication Settings ********** */

.auth-save-button {
  color: #ffffff;
  background: var(--secondary-btn);
  border: var(--secondary-btn);
  border-radius: 4px;
}

.auth-cancel-button {
  background: #e9e9e9 0% 0% no-repeat padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
}

.grupDiv {
  margin: 10px;
}

.headerDiv {
  display: flex;
  justify-content: space-between;
  width: 400px;
}

.groupMainDiv {
  margin-top: 20px;
}

.groupLabelDiv {
  display: flex;
  justify-content: flex-end;
  width: 400px;
}

.textLabel {
  text-align: left;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  font-size: 16px;
}

.authenticationText {
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  font-size: 20px;
  font-weight: bold;
}

.subTitleText {
  color: #333333;
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: medium;
  line-height: 17px;
}

.download-button {
  width: 80%;
  height: 32px;
  /* UI Properties */
  background: var(--primary-btn) 0% 0% no-repeat padding-box;
  border: 1px solid var(--primary-btn);
  border-radius: 4px;
  opacity: 1;
  color: white;
  margin: 10px;
  gap: 30px;
  display: flex;
  align-items: center;
}

.upload-button-style {
  width: 80%;
  height: 32px;
  background: var(--secondary-btn) 0% 0% no-repeat padding-box;
  border: 1px solid var(--secondary-btn);
  border-radius: 4px;
  opacity: 1;
  margin: 10px;
  color: white;
  display: flex;
  align-items: center;
  gap: 30px;
}

.candidate-image-div {
  width: 40px;
  height: 40px;
  /* UI Properties */
  background: transparent linear-gradient(315deg, #46006e 0%, #600097 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.candidate-logo-div {
  width: 24px;
  height: 24px;
  /* UI Properties */
  background: transparent url("../../assets/candidate-white.svg") 0% 0%
    no-repeat padding-box;
  opacity: 1;
  /* margin-left: auto;
  margin-right: auto; */
}

.job-logo-div {
  width: 28px;
  height: 28px;
  /* UI Properties */
  background: transparent url("../../assets/job-order.svg") 0% 0% no-repeat
    padding-box;
  opacity: 1;
  /* margin-left: auto;
  margin-right: auto; */
}

/* ********** user management ********* */

.inputDiv {
  width: 500px;
  height: 40px;
  border: 1px;
  border-style: solid;
}

.outer-div {
  width: 300px;
  height: 200px;
  border: 1px solid #000;
  position: relative;
  /* You can use relative or absolute positioning */
}

.inner-div {
  width: 80%;
  height: 80%;
  background-color: #f0f0f0;
  margin: 10px;
}

@media (max-width: 576px) {
  .custom-modal {
    max-width: 100%;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .custom-modal {
    max-width: 90%;
  }
}

@media (min-width: 769px) {
  .custom-modal {
    max-width: 70%;
  }
}

.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}

.resizableDiv {
  width: 100%;
  /* Set an initial width */

  /* Media queries for different screen resolutions */
  @media screen and (min-width: 1200px) {
    width: 100%;
    /* Adjust as needed for larger screens */
  }

  @media screen and (min-width: 1600px) {
    width: 100%;
    /* Adjust as needed for even larger screens */
  }

  /* Media queries for different screen scales */
  @media screen and (min-resolution: 144dpi) {
    /* Adjust styles for screens with higher resolution (e.g., scale 150%) */
    width: 50%;
  }
}

.user-create-modal {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.user-input-main-div {
  outline: auto;
  outline-color: var(--primary-color);
  height: 50px;
  width: 100%;
}

.user-input-main-input {
  margin: 10px;
  width: 70%;
  border: 1px solid transparent !important;
  font-size: 14px;
  color: var(--primary-color);
}

.user-select-main-input {
  margin: 2px;
  width: 25%;
  border: 1px solid transparent !important;
  font-size: 14px;
  color: var(--primary-color);
}

.input {
  border: none;
}

.add-more-button {
  width: 115px;
  height: 32px;
  /* UI Properties */
  background: var(--secondary-btn);
  opacity: 1;
  border: none;
  border-radius: 4px;
  color: #fff;
}

.cancel-button {
  width: 80px;
  height: 32px;
  /* UI Properties */
  background: #e9e9e9 0% 0% no-repeat padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  opacity: 1;
}

.save-button {
  width: 80px;
  height: 32px;
  /* UI Properties */
  background: var(--secondary-btn) 0% 0% no-repeat padding-box;
  border: 1px solid var(--secondary-btn);
  border-radius: 4px;
  opacity: 1;
  margin-left: 20px;
  color: white;
}

.role-select-div {
  width: 193px;
  height: 40px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c2c2c2;
  opacity: 1;
  text-align: center;
  color: #707070;
  cursor: pointer;
}

.role-selected-div {
  width: 193px;
  height: 40px;
  /* UI Properties */
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  opacity: 1;
  text-align: center;
  color: white;
  cursor: pointer;
  border: 1px solid gray;
}

.selected-role-text {
  margin: 10px;
  letter-spacing: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #f0f0f0;
}

.role-text {
  margin: 10px;
  letter-spacing: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #000;
}

.input-div-edit {
  display: grid;
}

.delete-button {
  width: 110px;
  height: 30px;
  background: #ff0000 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border: none;
  color: white;
}

.cancel-button-delete {
  width: 110px;
  height: 30px;
  /* UI Properties */
  background: #9f9f9f 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border: none;
  color: white;
}

.cancel-button-delete:hover {
  background: #707070 0% 0% no-repeat padding-box;
}

.gen-password {
  /* margin: 8px; */
  width: 50%;
  /* height: 31px; */
  border-radius: 4px;
  border: none;
  background: var(--primary-btn);
  color: white;
  font-size: 14px;
  margin-top: 4px;
  height: 40px;
}

/* *****************Theme Settings style **************** */

.column-style {
  border-left: 2px solid #600097;
}

.theme-main-div {
  margin: 30px;
}

.company-name-input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  opacity: 1;
  font-size: 17px !important;
}

.uploadLogoDiv {
  width: 100%;
  height: 100px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
}

.imageAlt {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
}

.button-div {
  margin-top: 20px;
  height: 32px;
  width: 121px;
}

.upload-button {
  background: var(--primary-btn) 0% 0% no-repeat padding-box;
  border: 1px solid var(--primary-btn);
  border-radius: 4px;
  opacity: 1;
  width: 121px;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-div {
  margin-top: 10px;
  height: 34px;
  text-align: left;
  font-style: italic;
  font-weight: normal;
  font-stretch: normal;
  font-size: 14px;
  line-height: 17px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.react-color-photoshop-picker-container {
  background-color: #008e21;
}

.exampleTempalte {
  background: #f8ebff;
  /* width: 500px; */
  height: 500px;
}

.example-nav {
  width: 599px;
  height: 50px;
  /* UI Properties */
  background: #600097 0% 0% no-repeat padding-box;
  opacity: 1;
}

.example-side-nav {
  width: 50px;
  height: 500px;
  background: #e0ccff 0% 0% no-repeat padding-box;
  opacity: 1;
}

.sample-div {
  width: 740px;
  height: 480px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #3800581a;
  border-radius: 20px;
  opacity: 1;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  /* margin-top: 5px; */
}

.sample-text {
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #600097;
  opacity: 1;
}

.table-main-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-top: 36px;
}

.custom-table {
  border-radius: 10px;
  width: 600px;
  text-align: center;
  outline: auto;
  outline-color: "#F0D4FF";
}

.custom-table th {
  background-color: #452a2a;
}

.custom-th {
  color: #600097;
  font-size: 16px;
  font-weight: bold;
}

.custom-td {
  text-align: center;
  font-size: 14px;
  border-bottom: 1px solid #e0d4e780;
  height: 40px;
}

.code-column-div {
  width: 320px;
  height: 190px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  margin-left: 30px;
  /* margin-top: 140px; */
  display: flex;
}

.color-code-sub-div {
  height: 140px;
  width: 54px;
  /* UI Properties */

  background: #600097 0% 0% no-repeat padding-box;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
  margin-top: 22px;
}

.hex-code-div {
  width: 97px;
  height: 32px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  color: #c2c2c2;
}

.color-code-div {
  width: 120px;
  height: 32px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  display: flex;
}

.custom-button-primary {
  width: 80px;
  height: 32px;
  /* UI Properties */
  background: var(--primary-btn) 0% 0% no-repeat padding-box;
  border: 1px solid var(--primary-btn);
  border-radius: 4px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.custom-button-secondary {
  width: 80px;
  height: 32px;
  /* UI Properties */
  background: var(--secondary-btn) 0% 0% no-repeat padding-box;
  border: 1px solid var(--secondary-btn);
  border-radius: 4px;
  opacity: 1;
  letter-spacing: 0px;
  color: #ffffff;
  margin-left: 30px;
}

.table-wrap {
  margin-top: 15px;
}

::file-selector-button {
  display: none;
}

.icon_img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.template-href,
.template-href:hover {
  text-decoration: none;
  color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.custom-datatable-title {
  font-size: 14px;
  color: 333333;
  opacity: 1;
  font-weight: bold;
  background: none;
  display: flex;
}

.ats-card-body {
  padding: 16px;
  width: 100%;
}

.ats-main-container {
  position: relative;
}

@media only screen and (max-width: 1508px) {
  .ats-card-body {
    padding: 10px;
  }
}

.settings-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  width: 35%;
  /* height: 50%; */
  background: white;
  z-index: 101;
  /* ensure the main container is above the backdrop */
  display: none;
  /* initially hide the container */
  box-shadow: 4px 0px 23px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.settings-container.open {
  display: block;
  /* display the container when open */
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden !important;
  background: rgba(0, 0, 0, 0.5);
  /* semi-transparent black */
  z-index: 100;
  /* ensure the backdrop is above other elements */
}

.setting-card-label {
  font-size: 14px;
  font-weight: 600;
}

.settings-card-input {
  padding: 5px;
  border: 1px solid #c2c2c2 !important;
  color: #707070;
  font-size: 14px !important;
  border-radius: 4px;
  width: 100%;
}

.settings-card-input::placeholder {
  color: #707070;
  font-size: 14px !important;
}

.settings-container table {
  width: 98%;
}

.setting-card-save,
.setting-card-cancel {
  background-color: #00c22d;
  border: 1px solid #00c22d;
  border-radius: 4px;
  padding: 2px;
  color: #ffff;
  width: 20%;
  font-size: 14px;
}

.setting-card-cancel {
  background-color: #e9e9e9;
  border: 1px solid #c2c2c2;
  color: black;
}

.settings-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.sidemenu-section-preview {
  min-height: 100vh !important;
  background: var(--primary-shade1) 0% 0% no-repeat padding-box;
}

.table-height {
  height: 100vh;
}

.role-selectable-div {
  border: 1px solid var(--primary-shade1);
  border-radius: 5px;
  display: flex;
}

.model-yes-btn-ats {
  width: 80px;
  height: 32px;
  /* UI Properties */
  background: #b1adb3 0% 0% no-repeat padding-box;
  border: 1px solid #b1adb3;
  border-radius: 4px;
  opacity: 1;
  color: #ffffff;
}
