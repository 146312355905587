.resume-head{
  height: 75dvh !important;
  overflow-y: hidden !important;
 }

 .resume-head::-webkit-scrollbar {
    display: none;/*Thsi is for chrome*/
}

.resume-head{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.resume-main{
  background: #ffffff;
  height: 90dvh;
}

.resume-drag{
    border: 1px dashed #C2C2C2; 
}

 .resumeImg{
  width: 12%;
 }

 .workImage{
  width: 50% !important;
  margin-left: -15px;
 }

  .eduImage{
  width: 40% !important;
  margin-left: -15px;
 }

 .uploadImg{
  width: 25% !important;
 }

 .arrowImg{
  width: 25%;
 }

 .WorkButton img{
  width: 45% !important;
 }

 .EduButton img{
  width: 35% !important;
 }

  .ProButton img{
  width: 35% !important;
 }

 .WorkButton button{
      background: #600097;
  color: #fff;
  outline: none;
 }

 .EduButton button{
      background: #600097;
  color: #fff;
  outline: none;
 }

  .ProButton button{
      background: #600097;
  color: #fff;
  outline: none;
 }

  .WorkButton button:hover{
          background: #600097;
  color: #fff;
    outline: none;
    box-shadow: none;
    box-shadow: 0 12px 6px 0 rgba(0,0,0,0.24),0 17px 90px 0 rgba(0,0,0,0.19);
  }

    .EduButton button:hover{
          background: #600097;
  color: #fff;
    outline: none;
    box-shadow: none;
    box-shadow: 0 12px 6px 0 rgba(0,0,0,0.24),0 17px 90px 0 rgba(0,0,0,0.19);
  }

    .ProButton button:hover{
          background: #600097;
  color: #fff;
    outline: none;
    box-shadow: none;
    box-shadow: 0 12px 6px 0 rgba(0,0,0,0.24),0 17px 90px 0 rgba(0,0,0,0.19);
  }

  .hr-line{
    border-style: dashed;
    border-width: 2px;
  }

  .text-p{
    color: #C2C2C2;
  }

.stars img{
  width: 30px;
}

.stars{
  margin-right: -15px;
}

/*CSS for right resume*/


.rightResume{
  border-left: 2px solid #600097;
}

.resume-sub{
  height: 65dvh;
  margin-inline: 20%;
}

.resume-sub-bg{
  background: #600097;
  min-height: 20px;
}

/* .list ul.a{
  list-style-type: square;
}

ul.a li::marker { 
  font-size: 30px;
  margin-bottom: 10px;
}

ul.a li{
  margin-bottom: -10px;
} */

ul.a {
  list-style-type: square;
  padding-left: 40px;
}

ul.a li {
  margin: 0px; /* Remove default margin from the list items */
  padding-left: -20px; /* Add padding to align text with the square bullets */
  margin-bottom: -10px;
  margin-left: 20px;
}

ul.a li::marker {
  font-size: 30px;
    color: #600097;
}

.resume-search{
  width: 3.5%;
}

#customRange::-webkit-slider-thumb {
  background-color: #600097;
  box-shadow: none !important; 
  /* Change the color of the slider handle in WebKit browsers */
}

#customRange::-moz-range-thumb {
  background-color: #600097;
  box-shadow: none !important;  /* Change the color of the slider handle in Firefox */
}

#customRange::-ms-thumb {
  background-color: #600097;
  box-shadow: none !important;  /* Change the color of the slider handle in Microsoft Edge */
}

/* Optional: Style the track (the bar that represents the range) */
#customRange::-webkit-slider-runnable-track {
  background-color: #C2C2C2;
  height: 7px !important;
}

#customRange::-moz-range-track {
  background-color: #C2C2C2;
  width: 15px !important;
   /* Change the color of the track in Firefox */
}

#customRange::-ms-track {
  background-color: #C2C2C2; /* Change the color of the track in Microsoft Edge */
}

.rightResume span{
  color: #8c8b8b !important;
}

.sve-btn{
  background-color: #00C22D !important;
  color: #fff !important;
}

.down-btn{
  background-color: #600097 !important;
  color: #fff !important;
}

/* Media queries*/

@media only screen and (max-width:768px){
  .resume-search{
    width: 4%;
  }
}

input{
  box-shadow: none !important;
}

input:focus{
  outline: none !important;
}

input::placeholder{
  font-size: 18px;
  font-weight: 400;
  color: #C2C2C2 !important;
}

input[type="text"]{
  font-size: 16px;
}