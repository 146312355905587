.email-editor-div {
  width: 100%;
  height: 100%;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
}

.email-editor-header {
  display: flex;
  justify-content: space-around;
  width: 160px;
  margin: 15px;
}

.text-area {
  height: 100%;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.uploadLogoDiv {
  width: 100%;
  height: 190px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
}

.input-style {
  height: 40px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  margin-top: 5px;
}

.input-div {
  display: grid;
  margin: 10px;
}

.upload-button-div {
  display: grid;
  align-items: center;
}

.divider {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid #c2c2c2;
  opacity: 0.25;
  margin-left: 20px;
  margin-right: 20px;
}

.label-style {
  text-align: left;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}


.imageAlt {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 150px;
    width: 150px;
  }

  .email-temp-textbox .ql-editor {
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    min-height: 300px !important;
    height: 150px !important;
    overflow-y: auto;
}

.errorText{
  color: red;
  font-size: 14px;
  padding: 5px;
}