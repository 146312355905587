@import url('../../Styles/sassStyles.css');

.list-head {
  min-height: 87vh;
  background-color: #fff;
  margin-top: 30px;
  border-radius: 15px;
}

.backImg {
  width: 38px;
}

.backImage {
  padding: 4px;
  padding-left: 2px;
  width: 25px;
  cursor: pointer;
}

.table-wrap {
  margin-top: 15px;
}

.rdt_Table {
  background: var(--primary-shade4) 0% 0% no-repeat padding-box !important;
  border: 1px solid var(--primary-shade2);
  border-radius: 10px;
  padding: 20px;
}

.rdt_TableBody {
  /* background: #F8EBFF40 0% 0% no-repeat padding-box !important; */
  border-top: 1px solid var(--primary-color);
  /* text-align: center !important; */
  /* border-radius: 10px; */
}

.table-wrap {
  .brFloq {
    /* border-bottom: 1px solid #60009780 !important; */
    font-size: 16px !important;
    font-weight: 600 !important;
  }
}

.kqWIVd {
  border-top: none !important;
}

div[role="columnheader"] {
  font-size: 14.5px;
  font-weight: 500;
  color: var(--primary-color);
}

.brFloq,
.emBIby,
.cTRXdr {
  color: var(--primary-color) !important;
}

.add-ques-btn {
  background-color: var(--secondary-btn) !important;
  border-radius: 4px;
  color: #ffffff;
  border: none;
  font-size: 14px !important;
  outline: none;
}

.add-ques-btn:hover {
  background-color: var(--secondary-btn) !important;
}

.load-text {
  font-size: 1.1rem !important;
}

@media (min-width: 1800px) {
  .ques-table {
    margin-inline: 200px !important;
  }
}

.questionlist-sidemenu-section {
  background: var(--primary-shade1) 0% 0% no-repeat padding-box;
}

.custom-table-cell {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.list-heading-label {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 600;
  margin-left: 12rem;
}

.queslist-details-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}