@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;800;900&display=swap);
@import url("../../Styles/sassStyles.css");

body {
  background: var(--primary-shade3) !important;
}

.container-nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
}

.jobs-head {
  text-align: center;
}

.font {
  font-weight: 500;
}

.search-btn {
  background-color: #e3c9f2 !important;
  border: 1px solid #e3c9f2 !important;
}

.table-wrap {
  margin-top: 15px;
}

.rdt_Table {
  background: #f8ebff40 0% 0% no-repeat padding-box !important;
  border: 1px solid #f0d4ff;
  border-radius: 10px;
  padding: 20px;
}

.rdt_TableBody {
  /* background: #F8EBFF40 0% 0% no-repeat padding-box !important; */
  border-top: 1px solid #60009780;
  /* text-align: center !important; */
  /* border-radius: 10px; */
}

.table-wrap {
  .brFloq {
    /* border-bottom: 1px solid #60009780 !important; */
    font-size: 16px !important;
    font-weight: 600 !important;
  }
}

.kqWIVd {
  border-top: none !important;
}

div[role="columnheader"] {
  font-size: 14.5px;
  font-weight: 500;
  /* cursor: pointer; */
  /* color: ; */
}

.brFloq,
.emBIby,
.cTRXdr {
  color: #11031a !important;
}

.ques-btn {
  background-color: var(--secondary-btn);
  border-radius: 4px;
  color: #ffff;
  /* margin-right: 20px; */
  /* height: 30px; */
  border: none;
  font-size: 14px;
  /* margin-right: 40px; */
}

.ques-btn:hover {
  background-color: var(--secondary-btn) !important;
}

input[type="date"]::-webkit-datetime-edit {
  display: none;
}

.canvas-question-label {
  color: #600097;
}

.schedule-btn img {
  font-family: "Roboto", sans-serif;
}

.link-input,
.question-select {
  border: 0.5px solid rgb(197, 196, 196) !important;
}

.offcanvas-body .form-select {
  background-color: #f7f7f7;
}

.custom-table-cell {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.custom-cell-job-role {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  cursor: pointer;
}

.custom-cursor {
  cursor: pointer;
}

.load-text {
  font-size: 1.1rem !important;
}

@media (min-width: 1800px) {
  .table-class {
    margin-inline: 200px !important;
  }
}

.job-list-label {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 600;
}

.job-csv-download {
  background: #f8ebff 0% 0% no-repeat padding-box;
  border: 1px solid #e3c9f2;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  font-size: 13px;
  padding: 5px;
  padding-inline: 5px;
}

.job-csv-download:hover {
  background: #46006e 0% 0% no-repeat padding-box;
  border: 1px solid #46006e;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  font-size: 13px;
  color: white;
  cursor: pointer;
  transition: all 0.01s ease-in;
  padding: 5px;
  padding-inline: 5px;
}

.skill-card-title {
  font-size: 20px;
  font-weight: 500;
}

.skill-input-div {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 7px;
  display: flex;
  flex-wrap: wrap;
}

.skill-input-ats-div {
  border-radius: 3px !important;
  font-size: 14px;
  background-color: #f7f7f7 !important;
  border: 1px solid #e9e9e9 !important;
}

.skill-input-ul {
  width: 100%;
  list-style-type: none;
  margin: 8px 8px 8px 8px;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
}

.skill-input-li {
  width: max-content;
  height: auto;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 3px;
  opacity: 1;
  text-align: center;
  color: #707070;
  font-size: 16px;
  padding: 5px;
}

.skill-input {
  border: none !important;
  width: 100%;
  height: auto;
  font-size: 14px !important;
  padding: 0px;
  margin: 8px;
  color: #707070;
  background-color: transparent;
}

.skill-input::placeholder,
.job-text-area::placeholder {
  color: #bdbdbd !important;
}

.skill-input .skills-required-title {
  font-size: 15px;
  font-weight: 500;
}

.joblist-details-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.job-icons-left {
  display: flex;
  /* background-color: bisque; */
  gap: 10px;
}

.add-job-dropdown {
  border: none !important;
  background: transparent;
}

input,
.job-text-area {
  border-radius: 3px !important;
  font-size: 14px;
  background-color: #f7f7f7 !important;
  border: 1px solid #e9e9e9 !important;
}

.job-order-create {
  height: 40px;
}
.add-job-modal {
  /* background-color: chartreuse; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add-job-modal label {
  font-size: 14px;
}

.action-div-add-job {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
  z-index: 0;
}

.cancel-button-style,
.close-button-style {
  width: 80px;
  height: 34px;
  /* UI Properties */
  background: #e9e9e9 0% 0% no-repeat padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  opacity: 1;
}

.close-button-style {
  background-color: #77dd77 !important;
  height: 30px;
  color: #ffffff;
  border: none;
}

.add-ats-job-button {
  background-color: #77dd77 !important;
  border-radius: 4px;
  color: #ffffff;
  border: none;
  font-size: 14px !important;
  width: 100px;
  height: 35px;
  outline: none;
}

.add-ats-job-button:hover {
  background-color: #77dd77 !important;
}

input[type="text"]::placeholder,
textarea::placeholder {
  font-size: 15px !important;
}

.import-job-sub-content {
  font-size: 12px;
  color: #a1a1a1;
}

.import-job-dragdrop {
  margin-top: 10px;
  border: 1px dashed #c9c9c9;
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.import-job-dragdropContent span {
  color: #46006e;
}

.import-download-temp {
  text-align: center;
}
.import-download-temp:hover {
  cursor: pointer;
}

.add-job-download-btn {
  background: none;
  border: none;
  color: #46006e;
  font-weight: 500;
  cursor: pointer;
}
.add-job-download-btn a,
.add-job-download-btn a:hover {
  color: #46006e;
  font-weight: 500;
  text-decoration: underline;
}

.upload-file-card,
.upload-file-green {
  border: 1px solid #c9c9c9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.upload-file-green {
  background-color: #daf3e0;
  border: 1px solid #17b05d;
}

.upload-file-name {
  font-size: 14px;
  font-weight: 700;
  margin-top: 3px;
}

.react-autosuggest__input {
  background-color: none !important;
}

.response-msg,
.response-msg-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  font-weight: 500;
  color: #17b05d;
  font-size: 14px;
}

.response-msg-error {
  color: #b01717;
}

.descriptionText {
  width: 100%;
}

.description-editor .ql-editor {
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  min-height: 250px !important;
  height: 150px !important;
  overflow-y: auto;
  background-color: #f7f7f7 !important;
}

.description-editor .ql-container.ql-snow {
  border: none;
}

.status-background {
  background-color: #f7f7f7 !important;
}

.job-order-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 400px;
  cursor: pointer;
  /* Set the maximum width */
}
