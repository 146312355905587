.cardBody {
  display: block;
  text-align: center;
  padding-top: 7px;
}

.cardTitle {
  height: 50px;
  opacity: 1;
  font-size: 26px;
  font-weight: bold;
  color: var(--primary-color);
}

.cardSubTitle {
  font-size: 14px;
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin: 20px;
}

.cardBackgroundTitle {
  width: 240px;
  height: auto;
  background: var(--primary-shade3) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}

.titleDiv {
  display: flex;
  justify-content: center;
}

.plusCardTitle {
  background: transparent linear-gradient(104deg, #008e21 0%, #00c22d 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  font-size: 20px;
  font-weight: bold;
  color: white;
  width: 240px;
  height: 50px;
  border-radius: 10px;
}

.premiumCardTitle {
    background: transparent linear-gradient(104deg, #46006E 0%, #600097 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    font-size: 20px;
    font-weight: bold;
    color: white;
    width: 240px;
    height: 50px;
    border-radius: 10px;
  }
