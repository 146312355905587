.actionButton{
    height: 40px;
    width: 40px;
    background-color: #E9E9E9;
    border: none;
    border-radius: 4px;
    opacity: 1;
}

.durationDiv{
    height: 40px;
    width: 130px;
    text-align: center;
}

.buttonDiv{
    width: 210px;
    height: 40px;
    border-radius: 4px;
    border:#E9E9E9;
}