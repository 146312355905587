.confirm-btn {
    width: 110px;
    height: 30px;
    background: #77DD77 0% 0% no-repeat padding-box;
    border: #77DD77;
    border-radius: 5px;
    opacity: 1;
    color: white;
}

.confirm-btn:hover {
    background: #56AE56 0% 0% no-repeat padding-box;
    border: #56AE56;
}