.companylist-container {
  margin: 20px;
  border-radius: 10px;
  padding: 20px;
  background-color: #ffffff;
}

.companylist-headers {
  display: flex;
  justify-content: space-between;
}

.company-list-label {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 600;
}

.companylist-icons {
  display: flex;
  /* position: relative; */
  /* width: 16%; */
}

.companylist-icon-left {
  display: flex;
  gap: 10px;
}

.company-csv-download {
  background: #f8ebff 0% 0% no-repeat padding-box;
  border: 1px solid #e3c9f2;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  font-size: 13px;
  padding: 5px;
  padding-inline: 5px;
}

.company-csv-download:hover {
  background: #46006e 0% 0% no-repeat padding-box;
  border: 1px solid #46006e;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  font-size: 13px;
  color: white;
  cursor: pointer;
  transition: all 0.01s ease-in;
  padding: 5px;
  padding-inline: 5px;
}

.add-ats-company-button {
  background-color: #77dd77 !important;
  border-radius: 4px;
  color: #ffffff;
  border: none;
  font-size: 14px !important;
  /* width: 100px; */
  height: 35px;
  outline: none;
}

.import-company-sub-content {
  font-size: 12px;
  color: #868080;
}

.import-company-dragdrop {
  margin-top: 10px;
  border: 1px dashed #868080;
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.import-compnay-dragdropContent span {
  color: #46006e;
}

.add-company-download-btn {
  background: none;
  border: none;
  color: #46006e;
  font-weight: 500;
  cursor: pointer;
}
.add-company-download-btn a,
.add-company-download-btn a:hover {
  color: #46006e;
  font-weight: 500;
  text-decoration: underline;
}

.import-Company-sub-content {
  font-size: 12px;
  color: #868080;
}

.import-download-temp {
  text-align: center;
}
.import-download-temp:hover {
  cursor: pointer;
}
