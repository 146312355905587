
.email-div {
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;
  background: #F8EBFF;
  flex-direction: column;
  gap:20px;
}

.varification-card {
  width: 500px;
  height: 500px;
  border-radius: 20px;
  align-items: center;
  display: grid;
  justify-content: center;
  text-align: center;
}

.content {
  top: 10%;
  left: 35%;
  margin: 30px;
  /* position: absolute; */
}

.circle {
  stroke-dasharray: 1194;
  stroke-dashoffset: 1194;
  animation: dc 1s ease-in-out;
  animation-fill-mode: forwards;
}

.tick {
  stroke-dasharray: 350;
  stroke-dashoffset: 350;
  animation: dt 0.8s ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0.95s;
}

@keyframes dc {
  from {
    stroke-dashoffset: 1194;
  }

  to {
    stroke-dashoffset: 2388;
  }
}

@keyframes dt {
  from {
    stroke-dashoffset: 350;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.main-heading-success{
  font-size: 40px;
  color: #333333;
  letter-spacing: 0;
}

.main-heading {
  font-size: 20px;
  color: #333333;
}

.sub-heading {
  letter-spacing: 0px;
  font-weight: normal;
  color: #333333;
  opacity: 1;
  font-size: 20px;
  text-align: center;
}

.ui-error{
	&-circle{
		stroke-dasharray:260.75219024795285px, 260.75219024795285px;
		stroke-dashoffset: 260.75219024795285px;
		animation: ani-error-circle 1.2s linear;
	}
	&-line1{
		stroke-dasharray: 54px 55px;
		stroke-dashoffset: 55px;
		stroke-linecap: round;
		animation: ani-error-line .15s 1.2s linear both;
	}
	&-line2{
		stroke-dasharray: 54px 55px;
		stroke-dashoffset: 55px;
		stroke-linecap: round;
		animation: ani-error-line .2s .9s linear both;
	}
}

@keyframes ani-error-line{
	to { stroke-dashoffset: 0; }
}

 @keyframes ani-error-circle {
		0% {
				stroke-dasharray: 0, 260.75219024795285px;
				stroke-dashoffset: 0;
		}
		35% {
				stroke-dasharray: 120px, 120px;
				stroke-dashoffset: -120px;
		}
		70% {
				stroke-dasharray: 0, 260.75219024795285px;
				stroke-dashoffset: -260.75219024795285px;
		}
		100% {
				stroke-dasharray: 260.75219024795285px, 0;
				stroke-dashoffset: -260.75219024795285px;
		}
}

.ui-error {
	width: 100px; 
  height: 50px;
	/* margin: 40px; */
	/* // border:1px solid #eee; */
}

.form-control-reset {
  width: 400px;
  height: 40px;
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border: 1px solid #E9E9E9 !important;
  border-radius: 4px;
  opacity: 1;

}

.reset-card-email {
  width: 450px;
  height: auto;
  /* padding: 30px; */
  background-color: #ffffff;
  box-shadow: 0px 0px 30px #3800581A;
  border-radius: 20px;
}

.reset-div{
  margin: 20px;
}

.reset-btn {
  width: 410px;
  height: 40px;
  /* UI Properties */
  background: #B59BD7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  border: none;
  color: #F7F7F7;
  font-size: 15px;
  margin-top: 6px;
}

.reset-btn:hover {
  background-color: #ceb6ec;
}

.reset-btn-disabled {
  width: 410px;
  height: 40px;
  /* UI Properties */
  background: #8e8c90 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  border: none;
  color: #F7F7F7;
  font-size: 15px;
  margin-top: 6px;
}