@import url("./Styles/sassStyles.css");

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

body {
  background-color: var(--primary-shade3) !important;
  z-index: 0;
  overflow: auto;
}
.container-nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
}
.search-container form,
.search-container form:hover,
.search-container form:active,
.search-container form:focus,
.search-container form:focus-visible {
  border: 1px solid #ddd;
  margin-bottom: 45px;
}
.search-container form input:hover,
.search-container form input:active,
.search-container form input:focus,
.search-container form input:focus-visible {
  outline: none;
}
.search-container input[type="text"] {
  /* padding: 6px;
  margin-top: 8px; */
  font-size: 15px;
  border: none;
}
.search-container button {
  /* float: right;
  padding: 6px 10px;
  margin-top: 8px;
  margin-right: 16px;
  background: #ddd; */
  font-size: 17px;
  border: none;
  cursor: pointer;
}

/* .search-container button:hover {
} */

/* .container-fluid {
  margin: 30px;
  padding: 20px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #3800581A;
  border-radius: 20px;
} */
/* table {
  box-shadow: 0px 0px 30px #3800581A;
  border-radius: 20px;
  border: 1px solid #F0D4FF;
  padding: 20px;
} */
th {
  color: #600097;
  text-align: center;
}
.table > thead > tr > th {
  border-bottom: 2px solid #60009780;
}

/* ul.pagination {
  
} */
ul.pagination li:nth-child(2) a {
  background: #600097 !important;
}
ul li:nth-child(2) a {
  color: #ffffff !important;
}
ul.pagination li a {
  color: #000;
}
.navbar {
  margin-bottom: 0px !important;
  /* height:10%; */
}
.navbar-brand {
  padding: 7px 0px !important;
}
.action-td {
  display: flex;
  justify-content: space-between;
}
.tooltip {
  display: flex;
  justify-content: center;
  position: relative;
  opacity: 1;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 80px;
  border: 1px solid #a9a9a9;
  background-color: #ffffff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #a9a9a9 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.no-border {
  border: none;
  background: transparent;
}

.margin-0 {
  margin: 0;
}
.fa-star {
  color: #888888;
  font-size: 17px !important;
}
.overalrating,
.aligned,
.textbox {
  border-radius: 10px;
  font-size: 13px;
  padding: 15px;
  background: #ffffff;
}
.rating-num {
  margin-left: 10px;
}

/* Question accordion */
.accordion {
  background: none;
  color: #444;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}
/* 
.active, .accordion:hover {
} */

.panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

/*Rating*/
.fa-star.checked,
.rate-text {
  color: #ffbf00;
}
/* sliders */

.switch {
  position: relative;
  display: inline-block;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  padding: 4px 5px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 9px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 21px;
  width: 49px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 22px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00c22d;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00c22d;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
  color: #ffffff;
}

.slider.round:before {
  border-radius: 50%;
  margin: -4px;
}
textarea,
textarea:hover,
textarea:active,
textarea:focus,
textarea:focus-visible {
  border: none;
  outline: none;
}
.viewrec-button-container .submit-btn {
  padding: 5px;
}
.submit-btn {
  background-color: #600097;
  border: 1px solid #46006e;
  color: #ffffff;
  border-radius: 4px;
}
.submit-btn:hover,
.submit-btn:active,
.submit-btn:focus {
  background-color: #46006e !important;
  border: 1px solid #46006e;
  color: #ffffff !important;
  border-radius: 4px;
}

.search-input {
  box-shadow: none !important;
  border-color: var(--primary-shade2) !important;
  outline: none;
  padding: 0px !important;
  background: var(--primary-shade4) 0% 0% no-repeat padding-box !important;
}

.search-icon {
  width: 20%;
  background-color: var(--primary-shade2);
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  /* margin-top: 8px; */
}

.search-input::placeholder {
  color: var(--primary-shade) !important;
  font-weight: 500;
  font-size: 16px !important;
}

.search-input:focus {
  border-color: var(--primary-shade2) !important;
}

.footer-copyrights {
  background-color: white;
  /* margin-bottom: 30px;
  border-radius: 10px; */
}

.rdt_TableCol_Sortable span {
  opacity: 1 !important;
}

.sidemenu-section {
  min-height: 100vh !important;
  background: var(--primary-shade1) 0% 0% no-repeat padding-box;
  width: 3%;
}

.icon-div {
  border-radius: 100%;
  background: var(--primary-shade2);
  padding: 3px 5px 5px 5px;
  border: none;
}

.backImage {
  padding: 4px;
  padding-left: 2px;
  width: 25px;
  cursor: pointer;
}

.table-label {
  color: var(--primary-shade);
  font-weight: 600;
}

.add-new-btn {
  background-color: var(--primary-btn) !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.add-save-btn {
  background-color: var(--secondary-btn) !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.edit-btn-question {
  color: var(--primary-btn);
  font-weight: 600;
}

.error-text {
  text-align: left;
  color: red;
  font-size: 14px;
  margin: 5px;
}

.loader-div {
  display: flex;
  justify-content: center;
  /* height: 90vh; */
  margin-top: 100px;
  align-items: center;
}

.loader-div-job {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}

.loader-div-createATS {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75vh;
}

/* "macstart": "react-scripts dev --host 182.65.82.93", */
.title-ats {
  font-size: 14px;
}
