.kanban-board-main-container {
  /* background-color: #f7f7f7 !important; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

body {
  background-color: #f7f7f7 !important;
}

.kanban-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #00000029;
  border-radius: 4px;
  height: 65px; */
}

.kanban-board-title {
  font-size: 18px;
  color: var(--primary-color);
  font-weight: 500;
}

.kanban-board-settings {
  background: none;
  border: 1px solid #f0d4ff;
  color: var(--primary-color);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  transform: rotate(0deg);
}

/* .kanban-board-settings:hover {
    border: 1px solid var(--primary-color);

} */

.rotate-img {
  transform: rotate(45deg);
  transition: transform 0.3s ease;
  /* Add a smooth transition for better visual effect */
}

.kanban-board-header {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #00000029;
  border-radius: 4px;
  opacity: 1;
  display: flex;
  align-items: flex-end;
  gap: 20px;
}

.kanban-search-input {
  border: 1px solid #e9e9e9;
  width: 270px;
  padding: 5px;
  border-radius: 4px;
  background: #f7f7f7 0% 0% no-repeat padding-box;
}

.kanban-search-input::placeholder {
  font-size: 16px;
  color: #333333 !important;
}

.kanban-search-btn-img {
  margin-left: -30px;
  /* margin-top: 7px; */
}

.kanban-board-status-0 {
  background: #f4ebff 0% 0% no-repeat padding-box;
  border: 1px solid #dfc4fe;
  color: var(--primary-color);
  font-weight: 500;
}

.kanban-board-status-1 {
  background: #e5f5ff 0% 0% no-repeat padding-box;
  border: 1px solid #7dcbfe;
  color: #0088ff;
  font-weight: 500;
}

.kanban-board-status-2 {
  background: #e2e2e2 0% 0% no-repeat padding-box;
  border: 1px solid #c2c2c2;
  color: #333333;
  font-weight: 500;
}

.kanban-board-status-3 {
  background: #feebff 0% 0% no-repeat padding-box;
  border: 1px solid #fec4f0;
  color: #c90287;
  font-weight: 500;
}

.kanban-board-status-4 {
  background: #ffede1 0% 0% no-repeat padding-box;
  border: 1px solid #ffab6e;
  color: #df6800;
  font-weight: 500;
}

.kanban-board-status-5 {
  background: #d3f8dc 0% 0% no-repeat padding-box;
  border: 1px solid #8fe9a5;
  color: #56ae56;
  font-weight: 500;
}

.ats-status-main-content {
  display: flex;
  justify-content: space-between;
}

.ats-status-sub-container {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border: 1px solid #ececec;
  border-radius: 7px;
  height: max-content;
  display: flex;
}

.kanban-sts {
  width: 199px;
}

.kanban-board-data {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
}

.kanban-board-can-name {
  font-weight: 500;
  font-size: 14px;
  width: 145px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: black;
  text-align: left;
}

.kanban-board-jobrole {
  font-size: 12px;
  color: #78959e;
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}

.kanban-board-data:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #dcdcdc;
  cursor: pointer;
}

.kanban-board-data-count-0 {
  background: #600097 0% 0% no-repeat padding-box;
}

.kanban-board-data-count-1 {
  background: #0088ff 0% 0% no-repeat padding-box;
}

.kanban-board-data-count-2 {
  background: #333333 0% 0% no-repeat padding-box;
}

.kanban-board-data-count-3 {
  background: #c90287 0% 0% no-repeat padding-box;
}

.kanban-board-data-count-4 {
  background: #df6800 0% 0% no-repeat padding-box;
}

.kanban-board-data-count-5 {
  background: #56ae56 0% 0% no-repeat padding-box;
}

@media only screen and (min-width: 1800px) {
  .kanban-sts {
    width: 270px;
  }
}

@media only screen and (max-width: 957px) {
  .kanban-sts {
    width: 110px;
  }

  .kanban-board-data {
    width: 100px;
  }
}
@media only screen and (max-width: 1300px) {
  .kanban-sts {
    width: 150px;
  }

  .kanban-board-data {
    width: 140px;
  }
}

.disabled-img {
  opacity: 0.1;
}

.kanban-filter-div {
  display: flex;
  gap: 20px;
}

.textarea-style-kanban {
  border: 1px solid #c2c2c2 !important;
  height: 124px;
  border-radius: 4px;
  margin: 5px;
  width: 100%;
}
