.admin-sidebar-section {
    min-height: 100vh;
}

.super-admin-main {
    background-color: #F7F7F7 !important;
    height: 100vh;
}

.form-select {
    --bs-form-select-bg-img: url('../../assets/dropdown-arrow.svg') !important;
}

.super-admin-dashboard-dropdown {
    width: 20% !important;
    background-color: #F8EBFF40 !important;
    border: 1px solid #F0D4FF !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: var(--primary-color) !important;
}

.super-admin-tenants-card,
.spr-admin-plan-data {
    display: flex;
    flex-wrap: nowrap;
    width: 20rem;
    height: 110px;
    justify-content: space-between;
    background-size: cover;
    border-radius: 10px;
    opacity: 1;
    color: white;
}

.super-admin-tenants-card-0 {
    background: #f6ebfe;
    box-shadow: inset 0px 0px 10px #f6ebfe;
    color: #600097;
    border: 1px solid #46006E33;
    background-size: cover;
}

.super-admin-tenants-card-1 {
    background:#e0f6ff;
    box-shadow: inset 0px 0px 10px #7DCBFE33;
    color: #0088FF;
    border: 1px solid #7DCBFE;
    background-size: cover;
}

.super-admin-tenants-card-2 {
    background: transparent url('../../assets/backTemp3.svg') 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 0px 10px #DF6800;
    border: 1px solid #DF6800;
    background-size: cover;
}

.super-admin-tenants-card-3 {
    background: transparent url('../../assets/backTemp4.svg') 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 0px 10px #008E21;
    border: 1px solid #008E21;
    background-size: cover;
}

.super-admin-tenants-card-4 {
    background: transparent url('../../assets/backTemp5.svg') 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 0px 10px #000000;
    border: 1px solid #000000;
    background-size: cover;
}

.spr-ad-status-count-container {
    display: flex;
    flex-direction: column;

}


.spr-admin-tenant-details {
    border-bottom: 1px solid #E2E2E2;
}

.spr-admin-plan-data {
    background: transparent url('../../assets/recruitmentData.svg') 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000001A;
    border: none;
    color: var(--primary-color);
}




@media only screen and (min-width: 1530px) {
    .super-admin-tenants-card {
        width: 20rem;
        height: 150px;
    }

    .spr-ad-status-count-container {
        gap: 10px;
    }

    .customize-graph {
        aspect-ratio: 16/3 !important;
    }

    .plan-card {
        padding: 30px !important;
    }

}

.spr-admin-status-count {
    margin-top: 10px;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 15px;
}


.plan-card-0 {
    background: transparent linear-gradient(138deg, #009FF9 0%, #00E2FF 100%) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: white;
    width: 30%;
}

.plan-card-1 {
    background: transparent linear-gradient(138deg, #00912F 0%, #00C440 100%) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: white;
    width: 30%;
}


.plan-card-2 {
    background: transparent linear-gradient(138deg, #4E006D 0%, #690094 100%) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: white;
    width: 30%;
}


.plan-card {
    background-color: white;
    box-shadow: 0px 3px 10px #0000001A;
    padding: 20px;
    display: flex;
    gap: 30px;
    width: 100%;
    border-radius: 4px;
}

.plan-detail {
    background: transparent url('../../assets/tenant-plan.svg') 0% 0% no-repeat padding-box;
    object-fit: cover;
    border-bottom: 1px solid #fffefe;
    height: 7rem;

}


.plan_count {
    font-size: 56px;
    font-weight: 500;
}


.usage_graph {
    background-color: #FFFFFF;
    border-radius: 4px;
    position: relative;
    box-shadow: 0px 3px 10px #0000001A;
}

.recharts-legend-item-text {
    color: black !important;
}

.recharts-cartesian-grid-vertical line {
    stroke-dasharray: 3 5 !important;
    opacity: 0;
}

.recharts-cartesian-grid-horizontal line {
    stroke-dasharray: 5 5 !important;
    opacity: 0.6;
}

.customize-graph {
    aspect-ratio: 16/4;
}