@import url("../../Styles/sassStyles.css");

.recording-container {
  display: flex;
}

.candidate-info {
  background-color: white;
  padding: 0 !important;
  /* height: 91vh !important; */
}

.interview-review-status b {
  color: #327a4a;
}

.interview-review-status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.share-button {
  margin-top: 10px;
  border: none;
  background-color: #46006e;
  color: white;
  font-size: 14px;
  padding: 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.copy-link-btn {
  border-radius: 4px;
  background-color: #600097 !important;
  color: #ffffff;
  border: 1px solid #46006e !important;
  font-size: 14px;
}

.share-link-info {
  color: #a1a1a1;
  font-size: 13px;
  font-style: italic;
  margin-top: 3%;
}

.view-ques-list {
  height: 58vh;
}
.ques-list {
  width: 100%;
  height: 53vh;
  overflow-y: auto;
}

.ques-container {
  border: 1px solid #dadada;
  border-radius: 10px;
  background: #f7f7f7;
  margin-bottom: 12px;
  margin-top: 10px;
}

.ques-active {
  background-color: #f8ebff;
  /* padding: 10px; */
  border: 1px solid #600097;
  border-radius: 10px;
}

.ques {
  padding: 10px;
}

.question-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.candidate-details-label,
.questions-label,
.overall-rating-label,
.popover_title {
  color: var(--primary-color);
  font-weight: 500;
  font-size: 15px;
}

.ans-text {
  border: 1px solid #008e21;
  border-radius: 5px !important;
  background: #00c22d !important;
  font-size: 12px;
  padding: 1px 8px !important;
  color: #ffffff !important;
}

.question-border {
  border: 1px solid #707070 !important;
  opacity: 0.5;
}

.hide-answer {
  display: none;
}

.view-recording-answer {
  font-size: 14px;
}

.rate-card {
  background: #ffedcc;
}

.rate-card-grey {
  background: #3800581a;
}

.rate-card-text {
  color: #ffa200;
}

.rate-card-text-grey {
  color: #a1a1a1;
}

.star-button-qn {
  background-color: transparent !important;
  border: none;
}

.video-preview-container {
  height: 75vh;
}

.recording-info {
  background-color: #ffffff;
  border-radius: 5px;
  height: 100%;
  position: relative;
}

.video-preview-view,
.video-preview {
  max-height: 80vh !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.video-preview-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.video-preview-heading-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.video-preview-icons {
  display: flex;
  gap: 10px;
}

.video-preview-icons img:hover {
  cursor: pointer;
}

.candidate-attention-score-container {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.candidate-attention-score {
  font-size: 14px !important;
  color: #fff;
  position: absolute;
}

.popover_wrapper_view {
  position: relative;
  display: inline-block;
}

.popover_content_view {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -370px;
  transform: translate(0, 10px);
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: 400px;
  margin-top: -10px;
  max-height: 70vh;
  overflow-y: auto;
}

.popover_wrapper_view:hover .popover_content_view {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}

.popover_heading {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.view-video-preview {
  height: 80%;
  border-radius: 5px;
  position: relative;
}

.view-video-preview video {
  object-fit: cover;
  /* aspect-ratio: 16/9; */
  border-radius: 10px;
  min-height: 60.5dvh;
}

.video-quality-info {
  display: flex;
  background-color: #ffedcc;
  border: 1px solid #ffa200;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 4px;
  /* margin-top: 20px; */
}

.view-video-quality-info {
  /* margin-top: -7em; */
  display: flex;
  background-color: #ffedcc;
  border: 1px solid #ffa200;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 4px;
}

.audit-log-container {
  width: 63.3%;
  height: 75.3%;
  background: #fff;
  border-radius: 5px;
  margin-right: 10px;
}

.audit-view-log-container {
  width: 65%;
  height: 84.5%;
  background: #fff;
  border-radius: 5px;
  margin-right: 10px;
  top: 12%;
}

.audit-main-conatiner {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.audit-log {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 20px;
}

.audit-log-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.score-heart {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
}

.heartP {
  font-size: 50px !important;
  color: #fff;
  position: absolute;
  margin-top: -10px;
}

.score_text {
  font-size: 16px;
  font-weight: 500;
}

.attention_score {
  margin-top: 10px;
}

.audit-text {
  font-size: 40px;
  font-weight: 500;
}

.chart-legend-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
}

.legend-container {
  flex: 4;
}

.chart-container {
  flex: 8;
}

.legend-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  gap: 8px;
  width: 100%;
}

.legend-box-1 {
  height: 30px;
  width: 180px;
  background-color: #bd6d6c;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  align-self: flex-end;
  padding: 5px;
}

.legend-box-3 {
  height: 30px;
  width: 180px;
  background-color: #e0bc78;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  border: none;
  padding: 5px;
}

.echarts-timeline {
  margin-top: -20px;
}

.feedback-info {
  background-color: #ffffff;
  border-radius: 5px;
}

.rating-num {
  margin-left: 10px;
}

.star-button {
  background-color: #ffffff;
  border: none;
}

.fa-star {
  color: #888888;
  font-size: 17px !important;
}

.fa-star.checked,
.rate-text {
  color: #ffa200;
}

.aligned-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aligned-dropdown-main:hover .dropdown-menu.show {
  display: block !important;
}

.dropdown-menu {
  min-width: 0px !important;
}

.aligned-dropdown {
  background-color: #f7f7f7 !important;
  border: 1px solid #e9e9e9 !important;
  color: #333333 !important;
  box-shadow: none !important;

  width: 58px !important;
  padding: 6.5px !important;
}

.aligned-dropdown:hover {
  background-color: #f7f7f7 !important;
  border: 1px solid #e9e9e9 !important;
  color: #333333 !important;
  box-shadow: none !important;
}

.aligned-dropdown:focus {
  background-color: #f7f7f7 !important;
  border: 1px solid #e9e9e9 !important;
  color: #333333 !important;
  box-shadow: none !important;
}

.aligned-dropdown-item {
  color: #333333 !important;
}

.aligned-dropdown-item:hover {
  background-color: #600097 !important;
  color: #fff !important;
}

.feedback-inner-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.feedback-form {
  flex: 8;
}

textarea {
  border: none !important;
  overflow: auto !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  resize: none !important;
}

textarea,
textarea:hover,
textarea:active,
textarea:focus,
textarea:focus-visible {
  border: none;
  outline: none;
}

.submit-btn {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-shade1);
  color: #ffffff;
  border-radius: 8px;
}

.submit-btn:hover,
.submit-btn:active {
  background-color: #46006e !important;
  border: 1px solid #46006e;
  color: #ffffff !important;
  border-radius: 4px;
}

@media only screen and (max-width: 1300px) {
  .video-preview-container {
    height: 77vh;
  }
}

@media only screen and (max-width: 992px) {
  .chart-legend-container {
    flex-direction: column;
    height: 100%;
    overflow-x: hidden !important;
  }

  .audit-log-container {
    margin-right: 20px;
    width: 94%;
  }

  .legend-inner-container {
    /* margin-top: -10px; */
    flex-direction: row;
  }

  .audit-log {
    margin-top: 10px;
  }

  .legend-box-1,
  .legend-box-3 {
    height: 25px;
  }
}

@media only screen and (min-width: 1600px) {
  .candidate-info {
    height: 85.5vh !important;
  }

  .ques-list {
    height: 58vh;
  }

  .audit-log-container {
    width: 63.5%;
  }

  .audit-view-log-container {
    width: 63.3%;
    height: 78%;
    top: 10%;
  }

  .video-preview-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
  }

  /* .view-video-quality-info {
    margin-top: -4em;
  } */
}

.ai-candidate-rating {
  background-color: inherit;
  border: none;
}

/* @media only screen and (max-width: 1208px) {
  .view-video-quality-info {
    margin-top: -2em;
  }
} */

.view-video-preview-container {
  height: 79%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.video-preview-container-main {
  height: 100%;
  /* background-color: #333333; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}
