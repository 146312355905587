

.form-select option {
    background-color: #E0CCFF;
}

.quillData{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #C2C2C2;
    border-radius: 4px;
    opacity: 1;   
}

.policy-doc-textbox .ql-editor {
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    min-height: 250px !important;
    height: 150px !important;
    overflow-y: auto;
}
.ql-btn .btn-save {
    background-color: #00C22D;
    color: #fff;
    border:none;
    border-radius: 5px;
}

.ql-btn .btn-save:hover {
    background-color: #2fa34a;
    color: #fff;
}

.ql-btn .btn-can {
    background-color: #E9E9E9;
    color: #000;
}


.ql-btn .btn-can:hover {
    background-color: #E9E9E9;
    color: #000;
}

/* media query */

@media (width >=1800px) {
    .ql-editor {
        min-height: 30rem;
    }
}


.document-label {
    text-align: left;
    font: normal;
    font-size: 14px;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    font-weight: bold ;
}


.document-input{
    font-size: 14px;
    height: 40px;
    color: #707070 !important;
}