@import url('../../../Styles/sassStyles.css');


.side-menu-container{
    
    overflow: hidden;
    background: var(--primary-shade1) 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}



.side-menu-icon{
cursor:pointer;
}

/* @media only screen and (max-width: 1508px){
    .side-menu-container{
        height: 100% !important;
    }
} */

.active-link{
    background-color: white;

    padding-top: 1px;
    padding-bottom: 3px;
    border-radius: 5px;
}