.left-column {
    background: transparent url('../../assets/logoBackground.png') 0% 0% no-repeat padding-box;
    background-size: cover;
    /* height: inherit; */
    opacity: 1;
    overflow: hidden;
}

.right-column {
    background: #F8EBFF 0% 0% no-repeat padding-box;
    opacity: 1;
}

.logo-div {
    height: 100vh;
    align-items: center;
    display: grid;
    justify-content: center;
}



.logo-heading {
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 37px;
    margin-top: 20px;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    text-align: center;
}


.login-card-email {
    width: 352px;
    height: auto;
    /* padding: 30px; */
    background-color: #ffffff;
    box-shadow: 0px 0px 30px #3800581A;
    border-radius: 20px;
}

.url-card {
    width: auto;
    height: auto;
    /* padding: 30px; */
    background-color: #ffffff;
    box-shadow: 0px 0px 30px #3800581A;
    border-radius: 20px;
}

.sign-up-card {
    width: 352px;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 12px 12px;
    opacity: 1;
    height: 168px;
    text-align: center;
}


.email-form-div {
    margin: 10px;
}

.insideDiv {
    display: grid;
}

.sub-title {
    font-size: 12px;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.tenant-details-div {
    display: grid;
    justify-content: center;
    margin: 30px;
}

.heading-label {
    font-size: 18px;
    font-weight: bold;
    color: #600097;
    text-align: center;
    margin: 5px;
}

.tenant-div {
    background: #E9E9E9;
    display: grid;
    justify-content: center;
    text-align: center;
    margin: 10px;
    border-radius: 10px;
}

.link-tag {
    font-size: 18px;
    color: #600097;
    text-decoration: none;
    font-weight: 500;
    overflow: hidden;
    margin: 23px;
    text-overflow: ellipsis;
}

.modal-div {
    display: grid;
    justify-content: center;
    text-align: center;
}

.modal-heading {
    font-size: 30px;
    font-weight: bold;
    color: #600097;
    text-align: center;
    margin: 5px;
}

.model-sub-text {
    font-size: 15px;
    color: #333333;
    font-weight: 500px;
}

.model-cancel {
    width: 80px;
    height: 32px;
    /* UI Properties */
    background: #E9E9E9 0% 0% no-repeat padding-box;
    border: 1px solid #C2C2C2;
    border-radius: 4px;
    opacity: 1;
}

.model-yes-btn {
    width: 80px;
    height: 32px;
    /* UI Properties */
    background: #600097 0% 0% no-repeat padding-box;
    border: 1px solid #600097;
    border-radius: 4px;
    opacity: 1;
    color: #ffffff;
}

.btn-div {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.tacbox {
    display: flex;
    margin-left: 5px;

}

.tacbox-input {
    height: 1em;
    width: 1em;
    vertical-align: middle;
    margin: 5px;
}

.eula-header {
    text-align: center;
    color: #600097;
    margin: 12px;
}

.eula-content-div {
    margin: 40px;
    text-align: justify;
}

.login-btn-disabled {
    width: 272px;
    height: 40px;
    /* UI Properties */
    background: #afaab4 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    border: none;
    color: #F7F7F7;
    font-size: 15px;
    margin-top: 6px;
}

/* 
.go2072408551 {
    padding: 8px 3px !important;
}

.go3958317564 {
    margin-inline: 7px !important;
} */