@import url("../../../Styles/sassStyles.css");

body {
  background-color: var(--primary-shade3) !important;
}
.container-nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
}
.container {
  width: 100% !important;
}

.login-card {
  width: 400px;
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0px 0px 30px #3800581a;
  border-radius: 20px;
}
.panel-title {
  color: #600097;
}

/* .form-group {
  margin: 20px;
} */

/* .form-control {
  margin-top: 8px;
} */
.form-control:focus {
  box-shadow: none;
}
input {
  border: 2px solid #e8e8e8;
}
.button-container {
  margin-top: 30px;
  text-align: center;
}
.disabled {
  background-color: #e9e9e9 !important;
  border: 1px solid #c2c2c2 !important;
  color: #b3adad !important;
  border-radius: 4px;
  margin-right: 10px !important;
}

.submit-btn-log {
  border-radius: 4px;
  margin-right: 10px !important;
}
.otp-active {
  background-color: #46006e !important;
  border: 1px solid #46006e;
  color: #ffffff !important;
}

.submit-btn-log:hover,
.submit-btn-log:focus {
  background-color: #46006e !important;
  border: 1px solid #46006e;
  border-radius: 4px;
  margin-right: 10px !important;
  color: #ffffff !important;
}
.cancel-btn,
.cancel-btn:hover,
.cancel-btn:active,
.cancel-btn:focus {
  background-color: #e9e9e9 !important;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
}
.navbar {
  margin-bottom: 0px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.agreement-body {
  margin: 70px;
  border-radius: 10px;
}
.agreement-body-div {
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0px 0px 30px #3800581a;
  border-radius: 10px;
}
