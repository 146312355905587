.popover_wrapper {
  position: fixed !important;
  bottom: 50px;
  right: 50px;
  width: 55px;
  height: 55px;
  background-color: #600097;
  color: white;
  border-radius: 50%;
  padding: 6px;
  font-size: 24px;
  cursor: pointer;
  z-index: 999 !important;
}

.popover_content {
  display: none !important;
  position: absolute !important;
  bottom: 30px;
  right: 40px;
  background-color: #fff; /* Change this to your desired background color */
  color: #000; /* Change this to your desired text color */
  border-radius: 5px;
  /* padding: 10px; */
  font-size: 24px;
  z-index: 1000 !important;
}

.popover_wrapper:hover .popover_content {
  display: block !important;
}

/* @media only screen and (min-width:768px){

    .popover_content{
    width: 300px !important;
    height: 350px !important;
    left: -300px !important;
    bottom: 10px !important;
    overflow: auto !important;
    padding: 0 !important;
    margin: 0 !important;
}

} */