@import url("../../Styles/sassStyles.css");

.can-head {
  text-align: center;
}

.qn-drop {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.top-schedule-btn {
  background-color: var(--secondary-btn);
  border-radius: 4px;
  color: #ffffff;
  border: none;
  /* height: 30px; */
  font-size: 14px;
  /* padding: 15px !important; */
  outline: none;
}

.top-schedule-btn:hover {
  background-color: var(--secondary-btn);
}

.schedule-btn {
  background-color: var(--secondary-btn);
  border-radius: 4px;
  color: #ffffff;
  border: none;
  margin-right: 40px;
  /* height: 30px; */
  font-size: 14px;
  padding: 15px !important;
  outline: none;
  width: 200px;
  /* transition: 1s ease-in-out; */
  /* padding-block: 10px !important; */
}

.schedule-btn:hover {
  /* transition: 1s ease-in-out; */
  background: var(--secondary-btn);
  color: white;
}

.schedule-btn:hover span {
  stroke-dashoffset: -480;
}

.green-button {
  background-color: #00c22d;
  /* Change the background color to green */
  color: white;
}

.disable-btn {
  background-color: rgb(200, 200, 200);
  cursor: not-allowed;
}

.disable-btn:hover {
  background-color: rgb(200, 200, 200);
}

.table-wrap {
  margin-top: 15px;
  margin-bottom: 15px;
}

.rdt_Table {
  background: #f8ebff40 0% 0% no-repeat padding-box !important;
  border: 1px solid #f0d4ff;
  border-radius: 10px;
  padding: 20px;
  min-height: auto;
  overflow-y: auto;
}

.table-wrap-ai-suggestion .rdt_Table {
  min-height: auto;
}

@media only screen and (max-width: 992px) {
  .rdt_Table {
    min-height: 60vh;
  }

  .table-wrap-ai-suggestion .rdt_Table {
    min-height: auto;
  }
}

.rdt_TableBody {
  /* background: #F8EBFF40 0% 0% no-repeat padding-box !important; */
  border-top: 1px solid #60009780;
  /* text-align: center !important; */
  /* border-radius: 10px; */
}

.table-wrap {
  .brFloq {
    /* border-bottom: 1px solid #60009780 !important; */
    font-size: 16px !important;
    font-weight: 600 !important;
  }
}

.kqWIVd {
  border-top: none !important;
}

div[role="columnheader"] {
  font-size: 14.5px;
  font-weight: "500";
  /* cursor: pointer; */
  color: #600097;
}

.brFloq,
.emBIby,
.cTRXdr {
  color: #600097 !important;
}

.input-with-icon {
  position: relative;
  top: 21px;
}

.calendar-icon {
  position: absolute;
  right: 0%;
  /* Adjust the left positioning as needed */
  top: 47%;
  padding-right: 10px;
  transform: translateY(-50%);
  font-size: 18px;
  /* Adjust the font size as needed */
  color: #3d3c3c;
  /* Customize the color of the icon */
}

.sche-input {
  background-color: #f7f7f7;
}

.sche-input::placeholder {
  font-size: 14px !important;
  padding-left: 5px;
  color: #333333 !important;
  /* font-weight: 500 !important; */
}

@media screen and (min-width: 992px) {
  .offcanvas-body label {
    font-size: 15px !important;
  }

  .offcanvas-body .canvas-questions {
    font-size: 15px !important;
  }
}

/* .candidate-tooltip {
  border: 1px solid rgb(124, 123, 123) !important;
  border-radius: 6.5px;
  padding: 4px !important;
  font-size: 12px;
  color: rgb(126, 118, 118);
}

.candidate-tooltip > .react-tooltip-arrow {
  border-right: 1px solid rgb(124, 123, 123) !important;
  border-bottom: 1px solid rgb(124, 123, 123) !important;
} */

.candidate-tooltip {
  border: 1px solid rgb(124, 123, 123) !important;
  border-radius: 6.5px !important;
  padding: 4px !important;
  font-size: 12px !important;
  color: rgb(126, 118, 118) !important;
  z-index: 1;
  max-width: 500px;
}

/* .candidate-tooltip>.react-tooltip-arrow {
  border-right: 1px solid rgb(124, 123, 123) !important;
  border-bottom: 1px solid rgb(124, 123, 123) !important;
} */

.rel-star {
  color: #600097 !important;
}

.fa-star.checked .rel-star {
  color: #600097 !important;
}

.rdt_TableRow:hover {
  background-color: var(--primary-shade2);
  cursor: default !important;
}

.custom-table-cell {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.size-text {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.canvas-question-label {
  display: flex;
}

.canvas-questions {
  align-items: flex-start;
  font-size: 14px !important;
  border: none !important;
}

.expandable-label {
  font-weight: 500;
  width: 20%;
  display: flex;
  justify-content: flex-start;
}

.expandable-value {
  display: flex;
  width: 50%;
}

.expandable-container {
  text-align: left;
  color: #333333;
  font-size: 14px;
  display: flex;
  /* justify-content: space-between; */
  /* background-color: #600097; */
  width: 100%;
  /* gap:10px; */
}

.load-text {
  font-size: 1.1rem !important;
}

@media (min-width: 1800px) {
  .can-table-class {
    margin-inline: 200px !important;
  }
}

.expandable-main-container {
  width: 100%;
  background: #f8ebff40 0% 0% no-repeat padding-box !important;
  border-bottom: 2px solid #e2e2e2;
}

.action-icon {
  margin: 2px;
  cursor: pointer;
  padding-top: 2px;
}

.flatpickr-time {
  display: none !important;
}

.candidate-csv-download {
  background: #f8ebff 0% 0% no-repeat padding-box;
  border: 1px solid #e3c9f2;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-inline: 5px;
  gap: 10px;
  font-size: 13px;
}

.candidate-csv-download:hover {
  background: #46006e 0% 0% no-repeat padding-box;
  border: 1px solid #46006e;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  font-size: 13px;
  color: white;
  padding-inline: 5px;
  cursor: pointer;
}

.candidate-dropdown {
  border: 1px solid #e9e9e9 !important;
  background-color: #f7f7f7 !important;
  display: flex;
  width: 250px;
  font-size: 15px !important;
  font-weight: 500 !important;
  color: black !important;
}

.fa-star.disabled-star {
  opacity: 0.5;
  /* Adjust the opacity to visually represent the disabled state */
  pointer-events: none;
  /* Prevent interactions with the disabled stars */
  filter: grayscale(100%);
  /*Grayscale the stars for a disabled appearance*/
}

.aligned-rating {
  /* border-right:1px solid rgb(240, 12, 12) !important; */
  margin: 5px !important;
}

.table-wrap-ai-suggestion .brFloq {
  font-size: 14.5px;
  font-weight: "500";
  /* cursor: pointer; */
  color: #327a4a !important;
}

.table-wrap-ai-suggestion .rdt_Table {
  background: var(--primary-shade4) 0% 0% no-repeat padding-box !important;
  border: none !important;
  padding: 10px !important;
}

.table-wrap-ai-suggestion .rdt_TableBody {
  border-top: 1px solid #77dd77 !important;
}

.table-wrap-ai-suggestion .rdt_TableRow:hover {
  background-color: #aeecae !important;
  cursor: default !important;
}

.ai-suggestion-title {
  border: 1px solid #77dd77;
  background-color: #77dd77;
  padding: 10px;
  border-radius: 10px;
  color: white;
  font-weight: 500;
}

.ai-suggestion {
  border: 1px solid #77dd77;
  border-radius: 10px;
}

.completed-color {
  background: #77dd77;
}

.scheduled-color {
  background: #689fcb;
}

.inProgress-color {
  background: #ffa76c;
}

.inComplte-color {
  background: #ff6961;
}

.react-responsive-modal .modal-close {
  display: none;
}

.canvas-header {
  background: none;
  border: none;
}

.canvas-div {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.reschedule-header {
  color: var(--primary-color);
}

.reschedule-save-btn {
  border-radius: 4px;
  background-color: var(--secondary-btn);
  color: #ffffff;
  border: var(--secondary-btn);
  font-size: 14px;
  width: 100px;
  height: 30px;
}

.reschedule-cancel-btn {
  width: 80px;
  height: 32px;
  font-size: 14px;
  background: #e9e9e9 0% 0% no-repeat padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  opacity: 1;
}

.filter-search-dropdown {
  box-shadow: none !important;
  border: none !important;
  padding: 0px !important;
  background: var(--primary-shade4) 0% 0% no-repeat padding-box !important;
  width: 100%;
  border-radius: 5px;
  outline: none !important;
}

.filter-div-search {
  outline: auto;
  outline-color: var(--primary-shade2) !important;
  display: flex;
  border-radius: 5px;
  width: 210px;
  height: 32px;
  margin-top: 2px;
  background: var(--primary-shade4) 0% 0% no-repeat padding-box !important;
}

.search-icon-filter {
  width: 25%;
  background-color: var(--primary-shade2);
  align-items: center;
  display: flex;
  justify-content: center;
  /* border-radius: 4px; */
  cursor: pointer;
  border: none;
  /* margin-top: 8px; */
}

.schedule-label {
  font-size: 14px;
  color: black;
  font-weight: normal;
}

.candidate-data-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* background-color: #689FCB; */
}

.candidate-list-label {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.job-order-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
  cursor: pointer;
  /* Set the maximum width */
}

@media (min-width: 1800px) {
  .job-order-name {
    max-width: 80% !important;
  }
}

.add-ats-candidate-button {
  background-color: #77dd77 !important;
  border-radius: 4px;
  color: #ffffff;
  border: none;
  font-size: 14px !important;
  /* width: 100px; */
  height: 35px;
  outline: none;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 40px;
  padding: 10px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #e9e9e9 !important;
  border-radius: 4px;
  background-color: #f7f7f7 !important;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  /* top: 51px; */
  width: 100%;
  /* border: 1px solid #aaa; */
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: var(--primary-shade3);
}

.react-autosuggest__suggestion {
  cursor: pointer;
  /* padding: 10px 20px; */
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.error-msg {
  font-size: 14px;
  color: red;
  font-weight: 500;
}

input[type="email"]::placeholder,
input[type="tel"]::placeholder {
  font-size: 14px !important;
}

.ats-dropdown {
  width: 110px;
  border: 1px solid var(--primary-shade2);
  border-radius: 5px;
}

.ats-dropdown:focus {
  border: 1px solid var(--primary-shade2);
}
