.create-tenant-label {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0px;
  color: var(--primary-color);
  opacity: 1;
  margin-top: 5px;
}

.textarea-style {
  border: 1px solid #c2c2c2 !important;
  height: 124px;
  border-radius: 4px;
  margin: 5px;
}

.input-style {
  border: 1px solid #c2c2c2 !important;
  border-radius: 4px;
  margin: 5px;
  padding: 10px;
  font-size: 15px !important;
}

.select-style {
  border: 1px solid #c2c2c2 !important;
  border-radius: 4px;
  margin: 5px;
  height: 40px;
  z-index: 1;
}

.select-style option {
  height: 36px !important;
  background: #f8ebff 0% 0% no-repeat padding-box !important;
  border: 1px solid #e3c9f2 !important;
  border-radius: 1px !important;
  opacity: 1 !important;
}

select option:hover {
  background-color: #f2f2f2; /* Set the hover background color */
}

.input-label {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  margin: 5px;
}

.column-heading {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: var(--primary-color);
  opacity: 1;
  margin: 5px;
}

.cancel-button-style {
  width: 80px;
  height: 35px;
  /* UI Properties */
  background: #e9e9e9 0% 0% no-repeat padding-box;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  opacity: 1;
}

.save-button-style {
  width: 80px;
  height: 35px;
  /* UI Properties */
  background: var(--secondary-btn) 0% 0% no-repeat padding-box;
  border: 1px solid var(--secondary-btn) 2;
  border-radius: 4px;
  opacity: 1;
  color: #ffffff;
}

.add-ques-button {
  background-color: var(--secondary-btn) !important;
  border-radius: 4px;
  color: #ffffff;
  border: none;
  font-size: 14px !important;
  width: 150px;
  height: 35px;
  outline: none;
}

.add-ques-button:hover {
  background-color: var(--secondary-btn) !important;
}

.action-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: -100px;
  z-index: 0;
}

.action-div-plan {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 100px;
  z-index: 0;
}

.premium-tag {
  height: 24px;
  width: 80px;
  background: transparent linear-gradient(107deg, #4e006d 0%, #690094 100%) 0%
    0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: #ffffff;
  font-weight: bold;
  margin: 1px;
  border: none;
}

.plus-tag {
  height: 24px;
  width: 80px;
  background: transparent linear-gradient(107deg, #00912f 0%, #00c340 100%) 0%
    0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: #ffffff;
  font-weight: bold;
  margin: 1px;
  border: none;
}

.basic-tag {
  height: 24px;
  width: 80px;
  background: transparent linear-gradient(107deg, #009ef9 0%, #00e2ff 100%) 0%
    0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: #ffffff;
  font-weight: bold;
  margin: 1px;
  border: none;
}

.freemium-other-tag {
  height: 24px;
  width: auto;
  background: transparent linear-gradient(107deg, #ae611d 0%, #df6800 100%) 0%
    0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: #ffffff;
  font-weight: bold;
  margin: 1px;
  border: none;
}

.freemium-tag {
  height: 24px;
  width: 80px;
  background: transparent linear-gradient(107deg, #4a85b4 0%, #689fcb 100%) 0%
    0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: #ffffff;
  font-weight: bold;
  margin: 1px;
  border: none;
}

.tenant-list-label {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 600;
  margin-left: 20px;
}

.tenant-plan-dropdown {
  padding-bottom: 150px;
}

.select-style:active,
.select-style:focus {
  outline: none !important;
}
