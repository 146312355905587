.confirm-cancel-btn {
    width: 80px;
    height: 32px;
    /* UI Properties */
    background: #b1adb3 0% 0% no-repeat padding-box;
    border: 1px solid #b1adb3;
    border-radius: 4px;
    opacity: 1;
    color: #ffffff;
}

.confirm-yes-btn {
    width: 80px;
    height: 32px;
    /* UI Properties */
    background: var(--primary-btn) 0% 0% no-repeat padding-box;
    border: 1px solid var(--primary-btn);
    border-radius: 4px;
    opacity: 1;
    color: #ffffff;
}